<template>
    <div>
        <div class="max-w-screen-2xl mx-auto">
            <div class="sm:pt-12 sm:pb-24 pt-8">
                <div class="flex justify-center">
                    <slot name="breadcrumbs"/>
                </div>

                <section class="grid gap-x-4 md:gap-y-10 mt-5 lg:grid-cols-3">

                    <!-- Filtreleme Alanı -->
                    <ClientOnly>
                      <TransitionRoot as="template" :show="sidebarOpen">
                        <Dialog as="div" class="relative z-300" @close="sidebarOpen = false">
                          <TransitionChild
                              as="template"
                              enter="transition-opacity ease-linear duration-300"
                              enter-from="opacity-0"
                              enter-to="opacity-100"
                              leave="transition-opacity ease-linear duration-300"
                              leave-from="opacity-100"
                              leave-to="opacity-0"
                          >
                            <div class="fixed inset-0 bg-black/10" />
                          </TransitionChild>

                          <div class="fixed inset-0 z-300 flex">
                            <TransitionChild
                                as="template"
                                enter="transition ease-in-out duration-300 transform"
                                enter-from="-translate-x-full"
                                enter-to="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leave-from="translate-x-0"
                                leave-to="-translate-x-full"
                            >
                              <DialogPanel
                                  class="relative flex w-full max-w-xs sm:max-w-md flex-1 flex-col bg-white text-primary pt-4 px-6"
                              >
                                <slot name="filter"/>
                              </DialogPanel>
                            </TransitionChild>
                            <div class="w-14 flex-shrink-0" aria-hidden="true">
                              <!-- Dummy element to force sidebar to shrink to fit close icon -->
                            </div>
                          </div>
                        </Dialog>
                      </TransitionRoot>
                    </ClientOnly>

                    <div class="relative" :class="[isShowFilter ? 'lg:col-span-3' : 'lg:col-span-4']">
                        <header class="grid sm:grid-cols-3 grid-cols-2 justify-between items-center pb-2 flex-wrap sm:flex-nowrap gap-2.5 sm:px-4" v-if="totalResults > 0">
                            <div class="flex justify-start items-center text-base text-primary cursor-pointer font-medium order-2 sm:order-1" @click="sidebarOpen = !sidebarOpen">
                                <LucideFilter class="h-4 w-4 mr-2"/>
                                Filtreler
                            </div>
                            <div class="flex justify-center items-center flex-1 order-1 sm:order-2 col-span-2 sm:col-span-1">
                                <!-- {/* Icon */} -->
<!--                                <span-->
<!--                                        class="h-32 w-32 opacity-10"-->
<!--                                        aria-hidden="true"-->
<!--                                        v-html="customData?.pageIcon"-->
<!--                                        v-if="customData?.pageIcon"-->
<!--                                ></span>-->
                                <div class="text-xl md:text-4xl capitalize whitespace-nowrap">
                                    <!-- {/* Page Title */} -->
                                    {{ customData?.pageTitle }}
                                </div>
                            </div>

                            <div class="flex items-center justify-end gap-2 flex-1 text-base text-primary cursor-pointer font-bold order-3">
                              <div class="flex-1 py-1.5 px-2 flex items-center justify-center rounded-full sm:hidden" :class="{'bg-gray-100' : mobileListingType === 2}" @click="mobileListingType = 2">
                                <LucideGrid2x2 class="h-4 w-4" />
                              </div>
                              <div class="flex-1 py-1.5 px-2 flex items-center justify-center rounded-full sm:hidden" :class="{'bg-gray-100' : mobileListingType === 1}" @click="mobileListingType = 1">
                                <LucideSquare class="h-4 w-4" />
                              </div>
                            </div>
                        </header>

                        <!-- Ürün listeleme bölümü -->
                        <div class="product-listing-wrapper" :class="mobileWrapperClass" v-if="items.length > 0">
                            <template v-for="product in items" :key="product._id">
                                <LayoutComponentProductCard :product="product" />
                            </template>
                        </div>

                        <slot name="emptyArea"/>

                        <slot/>
                    </div>
                </section>

                <!-- HTML Açıklama Alanı -->
                <slot name="htmlContentArea"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    FunnelIcon,
    Bars3Icon,
    Bars4Icon,
    QueueListIcon,
    TableCellsIcon, PlusSmallIcon, CheckIcon, MinusSmallIcon, XMarkIcon
} from "@heroicons/vue/24/outline";
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TransitionChild,
    TransitionRoot
} from "@headlessui/vue";

const {isShowFilter, isHideBreadCrumbs} = useHelper()
const {items, currentPage, perPage, totalResults, totalPage, baseParams, customData} = usePagination()
const {isDouble} = useListing()

const searchListType = useState("searchListType")
const sidebarOpen = useState("sidebarOpen", () => false);
const mobileListingType = useState('mobileListingType', () => isDouble.value ? 2 : 1)

const mobileWrapperClass = computed(() => {
  return mobileListingType.value === 2 ? 'grid-cols-2' : 'grid-cols-1'
})

</script>

<style scoped></style>
